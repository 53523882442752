import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["flashed"]
  connect() {

    this.element.classList.add(...this.flashedClasses)
    setTimeout(() => {
      this.element.classList.remove(...this.flashedClasses)
    }, 600)
  }
}
