import { Controller } from "@hotwired/stimulus"
import * as ActiveStorage from "@rails/activestorage"

// Connects to data-controller="uploader"
export default class extends Controller {
  connect() {
    console.log("connected", this.element)

    ActiveStorage.start()


    addEventListener("direct-upload:initialize", event => {
      const { target, detail } = event
      const { id, file } = detail
      const folder = "meow"
      // detail.directUploadData.key = `${folder}/${id}-${file.name}`
      console.log(detail)

      target.insertAdjacentHTML("beforebegin", `
<div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
<div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
<span class="direct-upload__filename"></span>
</div>
`)
      target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
    })

    addEventListener("direct-upload:start", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.remove("direct-upload--pending")
    })

    addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail
      console.log("Progress", progress)
      const progressElement = document.getElementById(`direct-upload-progress-${id}`)
      progressElement.style.width = `${progress}%`
    })

    addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--error")
      element.setAttribute("title", error)
    })

    addEventListener("direct-upload:end", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--complete")
    })
  }
  preview() {
    const imageFile = this.element.files[0]
    if (!imageFile || !imageFile.type.match('image.*')) {
      console.log("exiting", imageFile.type)
      return
    }
    const objURL = URL.createObjectURL(imageFile)
    const imageEl = document.getElementById('upload_preview')
    imageEl.src = objURL
    const dropzoneEl = document.getElementById('dropzone')
    dropzoneEl.classList.add('hidden')
    console.log("previewing...", imageEl)
    this.element.classList.add("hidden")
  }
}
