import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic-nested-field"
export default class extends Controller {
  static targets = ["nestedModel"]

  addField(e) {
    e.preventDefault()

    // Allows add field on enter key
    if (e.type === 'keyup' && e.key !== 'Enter') { return }

    // Copy last Field
    let lastField = this.nestedModelTargets[this.nestedModelTargets.length - 1];
    let newField = lastField.cloneNode(true);

    // Update field name value and empty value
    newField.querySelectorAll('input').forEach(input => {
      input.id = input.id.replace(/\_\d\_/g, `_${this.nestedModelTargets.length}_`);
      input.name = input.name.replace(/\[\d\]/g, `[${this.nestedModelTargets.length}]`);
      input.value = 0;
    })

    // Insert last to the form
    lastField.after(newField)
    newField.firstElementChild.focus()

  }
  removeField(e) {
    // Allows remove field on escape key
    if (e.type === 'keyup' && e.key !== 'Escape') { return }

    e.preventDefault()
    if (this.nestedModelTargets.length > 1) {
      let lastField = this.nestedModelTargets[this.nestedModelTargets.length - 2];
      e.target.closest('fieldset').remove()
      lastField.firstElementChild.focus()
    }
  }
}
