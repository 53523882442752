import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="validator"
export default class extends Controller {
  static targets = ['field']
  feedback(e) {
    const type = e.target.getAttribute('data-validator-type');
    if (type == 'required') {
      if (e.target.value.length > 0) {
        e.target.classList.remove('input-error')
      } else {
        e.target.classList.add('input-error')
      }
    }
  }
}
