import "../config"
import "../controllers"
import "../css/application.css"

// Support for viewTransition
addEventListener("turbo:before-frame-render", (event) => {
  if (document.startViewTransition) {
    const originalRender = event.detail.render
    event.detail.render = (currentElement, newElement) => {
      if (newElement.dataset.turboTransition && newElement.dataset.turboTransition === 'false') {
        originalRender(currentElement, newElement)
      } else {
        document.startViewTransition(() => originalRender(currentElement, newElement))
      }
    }
  }
})



// Keep state of collapsable sidebar
document.addEventListener("turbo:before-render", function (e) {
  if (document.documentElement.hasAttribute("data-turbo-preview") === true) {
    const sidebarCookie = document.cookie.split("; ").find(c => c.startsWith('sidebar_expanded=')).split('=')[1]
    const cachedSidebar = e.detail.newBody.querySelector('#sidebar')
    if (cachedSidebar) {
      cachedSidebar.dataset.expanded = sidebarCookie
    }
  }
})

Turbo.setConfirmMethod((message, element) => {
  let dialog = document.getElementById("turbo-confirm")
  dialog.querySelector("p").textContent = message
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener("close", () => {
      resolve(dialog.returnValue == "confirm")
    }, { once: true })
  })
})
