import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidepanel"
export default class extends Controller {
  static targets = ["modal", "content", "closer"]

  initialize() {
    console.log("initilalize side!")
  }

  connect() {

    setTimeout(() => {

      this.element.classList.add('bg-white/80')
      this.contentTarget.classList.add('translate-x-0')
      this.contentTarget.classList.remove('translate-x-full')
    }, 1);

  }
  hideSidepanel() {

    this.element.classList.remove('bg-white/80')

    this.contentTarget.classList.add('translate-x-full')
    this.contentTarget.classList.remove('translate-x-0')
    setTimeout(() => {
      this.element.parentElement.removeAttribute("src")
      this.element.remove()

    }, 300);
  }
  closeBackground(e) {
    // If modal is open, don't close sidepanel
    let turboModal = document.querySelector('#turbo-modal')
    if (turboModal) { return }
    //
    // If click inside the sidepanel, don't close it
    // and allow closer button to close 
    if (e && this.contentTarget.contains(e.target) && this.closerTarget && !this.closerTarget.contains(e.target)) {
      return;
    }

    this.hideSidepanel()
  }
}
