
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["custom"]
  static targets = ["panel"]
  showPanel(e) {
    e.preventDefault()
    this.panelTarget.classList.remove(this.customClass)
  }
  hidePanel(e) {
    e.preventDefault()
    // console.log(e.target)
    // console.log("contains?", this.element.contains(e.target))
    this.panelTarget.classList.add(this.customClass)
    //
  }
}
