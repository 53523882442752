// Import and register all your controllers from the importmap under controllers/*

import { application } from "../config/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import HwComboboxController from "@josefarias/hotwire_combobox"

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)


// const controllers = import.meta.globEager("./**/*_controller.js")
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })

application.register("hw-combobox", HwComboboxController)
registerControllers(application, controllers)
// console.log("COntrolelrs", controllers)

