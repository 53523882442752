import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.adjustTextareaHeight();
this.focusAndMoveCursorToEnd()
  }

  adjustTextareaHeight() {
    this.element.style.height = "auto";
    this.element.style.height = `${this.element.scrollHeight}px`;
  }
    refresh() {
    this.adjustTextareaHeight();
  }
  focusAndMoveCursorToEnd() {
    this.element.focus();

    const length = this.element.value.length;
    this.element.setSelectionRange(length, length);
  }
}

