import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
	static targets = ['submit', 'requiredTextArea']

	checkIfValid () {
		const currentTextLength = this.requiredTextAreaTarget.value.trim().length
		if (currentTextLength > 0 ) {
			this.submitTarget.removeAttribute('disabled');
		} else {
			this.submitTarget.setAttribute('disabled', 'disabled');
		}
	}
}
