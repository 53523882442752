import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(e) {
    e.preventDefault()
    const newState = this.element.dataset.expanded === "true" ? "false" : "true";
    this.element.dataset.expanded = newState

    document.cookie = `sidebar_expanded=${newState}; path=/`;
  }
}
